import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export function Redirect(props: { to: string }) {
  const nav = useNavigate();

  useEffect(() => {
    nav(props.to);
  }, [nav, props.to]);

  return null;
}
