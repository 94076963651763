import { TableRow } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import { EditableCol } from "../TableViewData";
import { EditCell } from "./EditCell";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useAsyncAction } from "nate-react-api-helpers";
import { showSnack } from "../../TSnackbar";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  actionWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  actionButton: {
    padding: 0,
  },
}));

export function InsertRow<T extends { id: number }>(props: {
  columns: EditableCol<T>[];
  insert: (input: T) => Promise<T>;
}) {
  const [row, setRow] = useState<Partial<T>>();
  const defaultRow = useRef<Partial<T>>();

  const insert = useAsyncAction(
    async (row: T) => {
      try {
        // set bool to false if not already set
        const boolKeys = props.columns
          .filter((c) => c.type === "bool" && c.key)
          .map((c) => c.key);
        for (let key of boolKeys) {
          // @ts-ignore
          if (row[key] === "") {
            // @ts-ignore
            row[key] = false;
          }
        }

        // set dates to today if not already set
        const dateKeys = props.columns
          .filter((c) => c.type === "date" && c.key)
          .map((c) => c.key);

        for (let key of dateKeys) {
          // @ts-ignore
          if (row[key] === "") {
            // @ts-ignore
            row[key] = moment().startOf("day");
          }
        }

        await props.insert(row);
        setRow(defaultRow.current);
        showSnack("Row added!", {
          variant: "success",
        });
      } catch (e: any) {
        showSnack(e.message, {
          variant: "error",
        });
      }
    },
    [props.insert]
  );

  useEffect(() => {
    const row = props.columns.reduce((acc, col) => {
      if (!col.key) return acc;
      switch (col.type) {
        case "date":
        case "date-time":
        case "time":
          // @ts-ignore
          acc[col.key] = "";
          return acc;
        case "money":
          // @ts-ignore
          acc[col.key] = 0;
          return acc;
        case "number":
          // @ts-ignore
          acc[col.key] = 0;
          return acc;
        default:
          // @ts-ignore
          acc[col.key] = "";
          return acc;
      }
    }, {} as T);

    setRow(row);
    defaultRow.current = row;
  }, [props.columns]);

  const styles = useStyles();

  if (!row) return null;

  return (
    <TableRow>
      <TableCell key="checkbox" padding="checkbox">
        <Checkbox checked={false} disabled={true} />
      </TableCell>
      {props.columns.slice(0, props.columns.length - 1).map((c, index) => (
        <EditCell
          row={row as T}
          onChange={setRow}
          key={c.name}
          value={valueSelector(c, row as T)}
          column={c}
          index={index}
          rowId="insert"
        />
      ))}
      <TableCell key="actions" style={actionStyle}>
        {row !== defaultRow.current && (
          <div className={styles.actionWrapper}>
            <Button
              className={styles.actionButton}
              size="small"
              variant="contained"
              disableElevation
              color="primary"
              onClick={() => insert.callback(row as T)}
            >
              Add
            </Button>
            <div style={{ width: 8 }} />
            <Button
              className={styles.actionButton}
              onClick={() => setRow(defaultRow.current)}
              size="small"
              variant="contained"
              disableElevation
              color="default"
            >
              Cancel
            </Button>
          </div>
        )}
      </TableCell>
    </TableRow>
  );
}

function valueSelector<T extends { id: number }>(c: EditableCol<T>, row: T) {
  if (!c.key) {
    if (c.type === "computed") return c.selector(row);
    return "";
  }

  const v = row[c.key];
  if (v === undefined) return "";
  return v;
}

export const actionStyle = {
  width: 150,
  padding: "0 12px 0 16px",
};
