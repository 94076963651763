import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import Divider from "@material-ui/core/Divider";
import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useNavigate, useLocation } from "react-router-dom";
import grey from "@material-ui/core/colors/grey";

const useStyles = makeStyles((theme) => ({
  tabs: {
    "&.MuiTabs-root": {
      minHeight: "38px",
    },
    "& .MuiTab-root": {
      minWidth: "0",
      minHeight: "0",
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      fontSize: "0.8rem",

      "&.Mui-disabled": {
        color: grey["400"],
      },
    },
  },
  right: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: theme.spacing(1),
  },
}));

export function useCurrentTab() {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  return search.get("t");
}

export function TTabs(props: {
  values: { name: string; disabled: boolean }[];
  onChange(value: string): void;
  rightAction?: JSX.Element;
}) {
  const styles = useStyles();

  const nav = useNavigate();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const t = search.get("t");

  const [tab, setTab] = useState(t);
  const onChange = props.onChange;

  useEffect(() => {
    if (!t) {
      const search = new URLSearchParams(location.search);
      search.set("t", props.values[0].name);

      // use replace so people can navigate backwards without
      // being stuck in redirection
      nav(
        {
          pathname: location.pathname,
          search: search.toString(),
        },
        { replace: true }
      );

      return;
    }

    setTab(t);
    onChange(t);
  }, [t, onChange, nav, location.search, location.pathname, props.values]);

  return (
    <>
      <Tabs
        value={tab || props.values[0].name}
        onChange={(e, v) => {
          if (v === undefined) return; // from text inputs in props.rightAction
          search.set("t", v);
          nav({
            pathname: location.pathname,
            search: search.toString(),
          });
        }}
        className={styles.tabs}
      >
        {props.values.map((v) => (
          <Tab
            key={v.name}
            value={v.name}
            label={v.name}
            disabled={v.disabled}
          />
        ))}

        <div className={styles.right}>
          <div>{props.rightAction}</div>
        </div>
      </Tabs>
      <Divider />
    </>
  );
}
