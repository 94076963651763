import { EditableCol } from "../../table/TableViewData";
import { DriverReport } from "../../../api/TimeSheet";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { TableViewDataSimple } from "../../table/TableViewDataSimple";
import { blankArray } from "../../table/TableViewSimple";
import { api } from "../../../api/API";
import { TLinkButton } from "../../TLinkButton";
import { Grid } from "@material-ui/core";
import { divisionOptions } from "../../project/Overhead";
import { useNavigate } from "react-router-dom";
import { SelectionContext } from "./SelectionProvider";
const timeFormat = "h:mm a";

export function ucFirst(word: string): string {
  if (!word) return "";

  if (word.length > 0) {
    return word[0].toUpperCase() + word.slice(1);
  }

  return word;
}

export const deliveryType = [
  { label: "Floating", id: "floating" },
  { label: "Delivery", id: "delivery" },
  { label: "Watering", id: "watering" },
  { label: "Other", id: "other" },
];

const cols: EditableCol<DriverReport>[] = [
  {
    name: "Type",
    type: "select-from-list",
    key: "deliveryType",
    displayKey: "deliveryTypeName",
    strictList: true,
    listSource: () => Promise.resolve(deliveryType),
  },
  {
    name: "Start Time",
    key: "startedAt",
    type: "date-time",
    displayFormat: timeFormat,
  },
  {
    name: "End Time",
    key: "endedAt",
    type: "date-time",
    displayFormat: timeFormat,
  },
  {
    name: "Duration",
    type: "computed",
    align: "right",
    sortable: false,
    selector: (row) => {
      if (!row.endedAt || !row.startedAt) return "";
      return (
        (
          moment(row.endedAt).diff(moment(row.startedAt), "minute") / 60
        ).toFixed(2) + " hrs"
      );
    },
  },
  {
    name: "From",
    type: "computed",
    selector: (s) =>
      s.deliveryType === "watering" ? s.projectName : s.pickupAtName,
  },
  {
    name: "To",
    type: "computed",
    selector: (s) => s.dropOffAtName,
  },
  {
    name: "Actions",
    width: 200,
    selector: (s) => (
      <Grid container>
        <Grid item>
          <TLinkButton title="Details" to={link(s)} size="small" />
        </Grid>
      </Grid>
    ),
    actions: true,
  },
];

function link(row: DriverReport) {
  return row.project || row.dropOffAtProject
    ? "/project/" +
        (row.project || row.dropOffAtProject) +
        "/daily-reports/" +
        row.timeSheetId +
        "/driver/" +
        row.id
    : "/time-sheet/" + row.timeSheetId + "/driver-report/" + row.id;
}

export function TimeSheetDriverTaskList(props: { timeSheetId: number }) {
  const [selection, setSelection] = useState<number[]>([]);
  const nav = useNavigate();

  const { updateSelection } = useContext(SelectionContext);

  useEffect(() => {
    updateSelection(selection);
  }, [selection]);

  return (
    <TableViewDataSimple
      filter={blankArray}
      search=""
      lockOrderBy
      orderByColumnName="startedAt"
      parentId={props.timeSheetId}
      fetcher={(input) => api.timeSheets.listDriverReports(input as any)}
      update={(input) => api.timeSheets.updateDriverReport(input as any)}
      insert={async (input) => {
        const rs = await api.timeSheets.updateDriverReport(
          Object.assign({}, input, {
            id: 0,
            timeSheet: props.timeSheetId,
          })
        );

        nav(link(rs));
        return rs;
      }}
      cols={cols}
      editable
      navigateTo={link}
      onSelectionChange={setSelection}
    />
  );
}
