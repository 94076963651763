export class SelectFromListSource {
  result: { [k: string]: Promise<any> } = {};

  constructor() {
    setInterval(() => {
      this.clearAll();
    }, 60 * 1000);
  }

  get<T>(key: string, fetcher: () => Promise<T>): Promise<T> {
    const prefetched = this.result[key];
    if (prefetched) return prefetched;

    const promise = fetcher();

    (async () => {
      // cleanup failed fetches
      try {
        await promise;
      } catch (e: any) {
        delete this.result[key];
      }
    })();

    this.result[key] = promise;
    return promise;
  }

  clearAll() {}
}
