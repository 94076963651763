import TableRow from "@material-ui/core/TableRow";
import { TableCell } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import React from "react";
import { Col } from "./TableViewData";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useNavigate } from "react-router-dom";
import grey from "@material-ui/core/colors/grey";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  hasButtons: {
    padding: "0 12px 0 16px",
  },
  link: {
    color: "inherit",
  },
  row: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: grey["50"],
    },
  },
  alignLeft: {
    textAlign: "left",
  },
  alignRight: {
    textAlign: "right",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
}));

export function TableViewDataRow<T extends { id: number }>(props: {
  checked: boolean;
  data: T;
  columns: Col<T>[];
  navigateTo?(row: T): string;
  onCheckChange(id: number, tf: boolean): void;
}) {
  const styles = useStyles();
  const nav = useNavigate();
  const url = props.navigateTo?.(props.data);

  return (
    <TableRow className={styles.row} onClick={url ? () => nav(url) : undefined}>
      <TableCell key="checkbox" padding="checkbox" onClick={stopPropagation}>
        <Checkbox
          checked={props.checked}
          onClick={() => props.onCheckChange(props.data.id, !props.checked)}
        />
      </TableCell>
      {props.columns.map((c) => (
        <TableCell
          key={c.name}
          className={clsx({
            [styles.hasButtons]: c.actions,
            [styles.alignLeft]: c.align === "left",
            [styles.alignRight]: c.align === "right",
            [styles.noWrap]: c.noWrap,
          })}
          style={c.width ? { width: c.width } : undefined}
          onClick={c.actions ? stopPropagation : undefined}
        >
          {c.selector(props.data)}
        </TableCell>
      ))}
    </TableRow>
  );
}

function stopPropagation(e: React.MouseEvent) {
  e.stopPropagation();
}
