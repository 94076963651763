import { useParams } from "react-router-dom";
import { useAsync2 } from "nate-react-api-helpers";
import { api } from "../../api/API";
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from "react";
import { Project } from "../../api/Projects";

type ContextType = {
  project: Project | null;
  LoadingOrErrorElement: JSX.Element | null;
};

const Context = createContext<ContextType>({
  project: null,
  LoadingOrErrorElement: null,
});

export function ProjectProvider(props: PropsWithChildren<{}>) {
  const params = useParams<{ id: string }>();
  const id = parseInt(params.id || "-");

  const fetcher = useAsync2((input) => api.projects.get(input), id, [id]);

  const ctx = useMemo(
    () => ({
      project: fetcher.result,
      LoadingOrErrorElement: fetcher.LoadingOrErrorElement,
    }),
    [fetcher.LoadingOrErrorElement, fetcher.result]
  );

  return <Context.Provider value={ctx}>{props.children}</Context.Provider>;
}

export function useProject() {
  const params = useParams<{ id: string }>();
  const ctx = useContext(Context);

  return {
    project: ctx.project,
    id: parseInt(params.id || "-"),
    projectLoadingEl: ctx.LoadingOrErrorElement,
  };
}
