import moment from "moment";
import { showSnack } from "../../TSnackbar";
import IconButton from "@material-ui/core/IconButton";
import React, { useContext } from "react";
import { useProject } from "../../project/UseProject";
import { TableContext } from "../TableProvider";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Grid } from "@material-ui/core";

export function DownloadButton(props: { tableName: string }) {
  const { project } = useProject();
  const ctx = useContext(TableContext);

  return (
    <Grid item>
      <IconButton
        size="small"
        onClick={async () => {
          try {
            await ctx.download(
              (project ? project.projectCode + "-" : "") +
                props.tableName +
                "-" +
                moment().format("YYYY-MM-DD") +
                ".csv"
            );

            showSnack("Downloaded", {
              variant: "success",
            });
          } catch (e: any) {
            console.error(e);
            showSnack(e.message, {
              variant: "error",
            });
          }
        }}
      >
        <GetAppIcon />
      </IconButton>
    </Grid>
  );
}
