import IconButton from "@material-ui/core/IconButton";
import { hideSnack, showSnack } from "../../TSnackbar";
import DeleteIcon from "@material-ui/icons/Delete";
import { Grid } from "@material-ui/core";
import React, { useCallback, useContext, useState } from "react";
import { TableContext } from "../TableProvider";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

export function DeleteButton(props: {
  disabled?: boolean;
  onRemove(): Promise<any>;
}) {
  const ctx = useContext(TableContext);
  const onRemove = props.onRemove;
  const callback = useCallback(async () => {
    const key = showSnack("Removing...");
    setShowConfirm(false);

    try {
      await onRemove();
      ctx.reload();

      hideSnack(key);
      showSnack("Removed items", {
        variant: "success",
      });
    } catch (e: any) {
      hideSnack(key);
      showSnack(e.message, {
        variant: "error",
      });
    }
  }, [onRemove, ctx]);

  const [showConfirm, setShowConfirm] = useState(false);

  return (
    <Grid item>
      <Dialog open={showConfirm}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to archive these items?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirm(false)}>Cancel</Button>
          <Button color="primary" onClick={callback}>
            Archive Items
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton
        disabled={props.disabled}
        size="small"
        onClick={() => setShowConfirm(true)}
      >
        <DeleteIcon />
      </IconButton>
    </Grid>
  );
}
