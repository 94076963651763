import React, { Suspense } from "react";
import "./App.css";
import {
  CircularProgress,
  CssBaseline,
  ThemeProvider,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { AuthProvider } from "nate-react-api-helpers/lib/Auth";
import { api } from "./api/API";
import { LoginPage } from "./pages/login/LoginPage";
import { RequireLogin } from "./pages/login/RequireLogin";
import { TDatePickerProvider } from "./pages/TDatePicker";
import { TSnackbarProvider } from "./pages/TSnackbar";
import { TConfirmProvider } from "./pages/TConfirm";
import { TableProvider } from "./pages/table/TableProvider";
import { ProjectProvider } from "./pages/project/UseProject";
import { ProjectsPages } from "./pages/project/ProjectsPages";
import { Container } from "./pages/Container";
import { Redirector } from "./misc/Redirector";
import { Outlet, Route, Routes } from "react-router-dom";
import { Redirect } from "./misc/Redirect";

const CreateTimeSheetPage = React.lazy(
  () => import("./pages/timeSheets/timeSheet/CreateTimeSheetPage")
);
const PayrollPage = React.lazy(() => import("./pages/payroll/PayrollPage"));
const EmployeeListPage = React.lazy(
  () => import("./pages/employees/EmployeeListPage")
);
const ForgotPassword = React.lazy(() => import("./pages/login/ForgotPassword"));
const ResetPassword = React.lazy(() => import("./pages/login/ResetPassword"));
const EmployeeDetailPage = React.lazy(
  () => import("./pages/employees/EmployeeDetailPage")
);
const CreateEmployeePage = React.lazy(
  () => import("./pages/employees/CreateEmployeePage")
);
const CreateProjectPage = React.lazy(
  () => import("./pages/project/CreateProjectPage")
);
const ProjectDetailPage = React.lazy(
  () => import("./pages/project/ProjectDetailPage")
);
const VehicleDetailPage = React.lazy(
  () => import("./pages/vehicles/VehicleDetailPage")
);
const VehicleListPage = React.lazy(
  () => import("./pages/vehicles/VehicleListPage")
);
const CreateVehiclePage = React.lazy(
  () => import("./pages/vehicles/CreateVehiclePage")
);
const CreateEquipmentPage = React.lazy(
  () => import("./pages/equipment/CreateEquipmentPage")
);
const EquipmentDetailPage = React.lazy(
  () => import("./pages/equipment/EquipmentDetailPage")
);
const EquipmentListPage = React.lazy(
  () => import("./pages/equipment/EquipmentListPage")
);
const TimeSheetPage = React.lazy(
  () => import("./pages/timeSheets/TimeSheetPage")
);
const DailyReport = React.lazy(
  () => import("./pages/project/dailyReport/DailyReport")
);
const TimeSheetSiteDetailPage = React.lazy(
  () => import("./pages/timeSheets/timeSheet/TimeSheetSiteDetailPage")
);
const TimeSheetDriverDetailPage = React.lazy(
  () => import("./pages/timeSheets/timeSheet/TimeSheetDriverDetailPage")
);
const TimeSheetDriverTaskDetailPage = React.lazy(
  () => import("./pages/timeSheets/timeSheet/TimeSheetDriverTaskDetailPage")
);
const InboxPage = React.lazy(() => import("./pages/inbox/InboxPage"));
const MobileBlastPage = React.lazy(
  () => import("./pages/mobileblast/MobileBlastPage")
);
const Documents = React.lazy(() => import("./pages/documents/Documents"));
const ProjectCostingPage = React.lazy(
  () => import("./pages/project/costing/ProjectCostingPage")
);
const ServiceRecordListPage = React.lazy(
  () => import("./pages/serviceRecords/ServiceRecordListPage")
);
const CreateServiceRecordPage = React.lazy(
  () => import("./pages/serviceRecords/CreateServiceRecordPage")
);
const ServiceRecordDetailPage = React.lazy(
  () => import("./pages/serviceRecords/ServiceRecordDetailPage")
);
const ReportsPage = React.lazy(() => import("./pages/reports/ReportsPage"));
const ServiceRequestDetailPage = React.lazy(
  () => import("./pages/serviceRequests/ServiceRequestDetailPage")
);
const ServiceRequestListPage = React.lazy(
  () => import("./pages/serviceRequests/ServiceRequestListPage")
);
const CreateServiceRequestPage = React.lazy(
  () => import("./pages/serviceRequests/CreateServiceRequestPage")
);

export const terrainGreen = "#789C48";
export const terrainBrown = "#3F3127";

const theme = createTheme({
  palette: {
    primary: {
      main: terrainGreen,
    },
    secondary: {
      main: "#906444",
    },
  },
  overrides: {
    MuiCard: {
      root: {
        flexShrink: 0,
      },
    },
  },
});

const SuspenseLayout = () => (
  <React.Suspense
    fallback={
      <Container maxWidth={1000}>
        <CircularProgress />
      </Container>
    }
  >
    <Outlet />
    <RequireLogin />
  </React.Suspense>
);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <TConfirmProvider>
        <TDatePickerProvider>
          <TSnackbarProvider>
            <AuthProvider onPing={() => api.ping()} pingInterval={30 * 1000}>
              <TableProvider>
                <CssBaseline />

                <Routes>
                  <Route element={<SuspenseLayout />}>
                    <Route path="/login" element={<LoginPage />} />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />
                    <Route
                      path="/reset-password/:code"
                      element={<ResetPassword />}
                    />
                    <Route path="/documents" element={<Documents />} />
                    <Route
                      path="/employees/create"
                      element={<CreateEmployeePage />}
                    />
                    <Route path="/employees" element={<EmployeeListPage />} />
                    <Route path="/payroll" element={<PayrollPage />} />
                    <Route
                      path="/communications"
                      element={<MobileBlastPage />}
                    />
                    <Route path="/time-sheets" element={<TimeSheetPage />} />
                    <Route
                      path="/time-sheet/create"
                      element={<CreateTimeSheetPage />}
                    />
                    <Route
                      path="/time-sheet/:timeSheetId/snow"
                      element={<TimeSheetSiteDetailPage />}
                    />
                    <Route
                      path="/time-sheet/:timeSheetId/driver-report/:driverTaskId"
                      element={<TimeSheetDriverTaskDetailPage />}
                    />
                    <Route
                      path="/time-sheet/:timeSheetId/driver-report"
                      element={<TimeSheetDriverDetailPage />}
                    />
                    <Route
                      path="/employee/:id"
                      element={<EmployeeDetailPage />}
                    />
                    <Route
                      path="/vehicles/create"
                      element={<CreateVehiclePage />}
                    />
                    <Route path="/vehicles" element={<VehicleListPage />} />
                    <Route
                      path="/vehicle/:id"
                      element={<VehicleDetailPage />}
                    />
                    <Route
                      path="/service-records/create"
                      element={<CreateServiceRecordPage />}
                    />
                    <Route
                      path="/service-records"
                      element={<ServiceRecordListPage />}
                    />
                    <Route
                      path="/service-record/:id"
                      element={<ServiceRecordDetailPage />}
                    />
                    <Route
                      path="/service-request/create"
                      element={<CreateServiceRequestPage />}
                    />
                    <Route
                      path="/service-requests"
                      element={<ServiceRequestListPage />}
                    />
                    <Route
                      path="/service-request/:id"
                      element={<ServiceRequestDetailPage />}
                    />
                    <Route
                      path="/equipments/create"
                      element={<CreateEquipmentPage />}
                    />
                    <Route path="/equipments" element={<EquipmentListPage />} />
                    <Route
                      path="/equipment/:id"
                      element={<EquipmentDetailPage />}
                    />
                    <Route
                      path="/project/create"
                      element={<CreateProjectPage />}
                    />
                    <Route
                      path="/project/:id/costing"
                      element={
                        <ProjectProvider>
                          <ProjectCostingPage />
                        </ProjectProvider>
                      }
                    />
                    <Route
                      path="/project/:id/daily-reports/:timeSheetId/:type/:siteId"
                      element={
                        <ProjectProvider>
                          <DailyReport />
                        </ProjectProvider>
                      }
                    />
                    <Route
                      path="/project/:id"
                      element={
                        <ProjectProvider>
                          <ProjectDetailPage />
                        </ProjectProvider>
                      }
                    />
                    <Route path="/projects" element={<ProjectsPages />} />
                    <Route path="/reports/:id" element={<ReportsPage />} />
                    <Route path="/reports" element={<ReportsPage />} />
                    <Route path="/inbox" element={<InboxPage />} />
                    <Route path="/" element={<Redirector />} />
                    <Route path="*" element={<Redirect to="/" />} />
                  </Route>
                </Routes>
              </TableProvider>
            </AuthProvider>
          </TSnackbarProvider>
        </TDatePickerProvider>
      </TConfirmProvider>
    </ThemeProvider>
  );
}

export default App;
